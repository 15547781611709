import * as Sentry from "@sentry/vue";
import { defineNuxtPlugin } from "nuxt/app";

export default defineNuxtPlugin((nuxtApp) => {
	//   const router = useRouter()
	const runtimeConfig = useRuntimeConfig();

	if (!runtimeConfig.public.sentry.dsn) {
		return;
	}

	if (process.env.ENV === "production") {
		Sentry.init({
			app: nuxtApp.vueApp,
			dsn: runtimeConfig.public.sentry.dsn,
			environment: runtimeConfig.public.sentry.environment,
			ignoreErrors: ["Page Not Found"]
		});
	}
});
