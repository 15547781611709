import { reactive, ref, onMounted, onUnmounted } from "vue";

const breakpoints = {
	xs: 0,
	sm: 640,
	md: 768,
	lg: 1024,
	xl: 1280
};

const useBreakpoints = () => {
	const windowWidth = ref(window.innerWidth);
	const width = ref("xs");

	const updateWidth = () => {
		const currentWidth = window.innerWidth;
		windowWidth.value = currentWidth;

		// Determine the width based on breakpoints
		for (const breakpoint in breakpoints) {
			if (currentWidth >= breakpoints[breakpoint]) {
				width.value = breakpoint;
			}
		}
	};

	onMounted(() => {
		window.addEventListener("resize", updateWidth, { passive: true });
	});

	onUnmounted(() => {
		window.removeEventListener("resize", updateWidth, { passive: true });
	});

	updateWidth(); // Initialize width

	return reactive({ width, windowWidth, breakpoints });
};

export default useBreakpoints;
