<template>
	<NuxtLink
		:id="id"
		ref="Tile"
		:to="link"
		class="post-tile"
	>
		<img
			v-if="isNews"
			class="post-image object-cover"
			:src="sourceUrl || ''"
			:alt="`post image ${id}`"
		/>
		<div
			v-else
			class="post-image"
			:style="`background-image:url(${parsedImage})`"
		/>
		<div class="post-content">
			<div class="post-metadata mb-[8px]">
				<span>{{ truncateText(categoryParsed, 15) }}</span>
				<span>
					{{ isNews ? content_read_time : calculateReadingTime(content) }} min read
				</span>
			</div>
			<h3
				:class="{ 'news-title': isNews }"
				v-html="reponsiveTruncateText(title)"
			/>
		</div>
	</NuxtLink>
</template>

<script setup lang="ts">
import categories from "~/utils/categories-data.json";

type Post = {
	id?: number;
	sourceUrl: string | null;
	slug: string;
	title: string;
	category: number;
	content: string;
	isNews?: boolean;
	content_read_time?: number;
};

const Tile = ref<HTMLElement | null>(null);
const { category, sourceUrl, id, isNews, slug, content_read_time } =
	defineProps<Post>();

const { env } = useRuntimeConfig().public;

const link = computed(() => {
	if (isNews) {
		return `/news/${slug}`;
	}

	return `/blog/${slug}/`;
});
const categoryParsed = computed(() => {
	if (!category && isNews) {
		return "Stock News";
	}
	const el = categories.find((item) => item.id === category);
	if (el) {
		return el.name;
	}

	return null;
});

const parsedImage = computed(() => {
	if (isNews) return "";

	if (!sourceUrl) return "";

	if (!["development", "production"].includes(env)) {
		return sourceUrl;
	}

	if (
		sourceUrl &&
		sourceUrl.includes("/cdn-cgi/image/quality=90,format=webp,width=440")
	) {
		return sourceUrl;
	}

	return `https://content.timothysykes.com/cdn-cgi/image/quality=90,format=webp,width=440/${sourceUrl}`;
});

const reponsiveTruncateText = (text: string) => {
	if (!text) return "";

	if (process.client) {
		if (window.innerWidth > 1280) {
			return truncateText(text, isNews ? 80 : 49);
		}

		return truncateText(text, isNews ? 120 : 62);
	} else {
		return truncateText(text, isNews ? 56 : 49);
	}
};

// onMounted(() => {
// 	if (process.client) {
// 		const image_container = document
// 			.getElementById(id)
// 			?.querySelector(".post-image");

// 		console.log({ image_container });

// 		const image = sourceUrl.includes(
// 			"/cdn-cgi/image/quality=90,format=webp,width=440"
// 		)
// 			? sourceUrl.split("ormat=webp,width=440/")[1]
// 			: sourceUrl;
// 		image_container.style.backgroundImage = `url(https://content.timothysykes.com/cdn-cgi/image/quality=90,format=webp,width=440/${image})`;
// 	}
// });
</script>

<style lang="scss" scoped>
@import "../assets/utils.scss";

.post-tile {
	border-radius: 8px;
	overflow: hidden;
	filter: drop-shadow(0px 7px 20px rgba(0, 0, 0, 0.1));
}

.post-image {
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	background-color: $primary-300;
	overflow: hidden;
	background-size: cover;
	background-position: center;
	object-fit: cover;

	width: 100%;
}

.post-content {
	padding: 8px 16px 16px;
	background-color: white;

	@include breakpoint(md) {
		min-height: 92px;
		height: 100%;
	}
}

.post-content h3 {
	color: $neutral-800;
	/* Heading/H6 */
	font-family: Fira Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 120%;
	/* 21.6px */
	&.news-title {
		font-size: 16px;
	}
}
</style>
