<template>
	<Head>
		<link
			rel="preload"
			as="font"
		/>
		<link
			rel="preconnect"
			href="https://fonts.googleapis.com"
		/>
		<link
			rel="preconnect"
			href="https://fonts.gstatic.com"
			crossorigin
		/>
	</Head>

	<Navigation />

	<Transition>
		<ContentSidebar v-if="store.contentSidebar.show" />
	</Transition>

	<slot />

	<Footer />

	<AdButlerModal
		v-if="
			showAds &&
			showAdButlerModal &&
			!['news', '404', '500'].includes(router.fullPath)
		"
		:showAdButlerModal="showAdButlerModal"
		@close="hideModal"
	/>

	<!-- <div
		v-else-if="
			!showAds &&
			showAdButlerModal &&
			!['news', '404', '500'].includes(router.fullPath)
		"
		id="adbutler-modal-fallback"
	></div> -->
	<template v-else-if="!showAds && showAdButlerModal">
		<div
			id="adbutler-modal-fallback"
			class="!hidden"
		></div>
	</template>
</template>

<script setup>
import { fetchFallbackAdbutlerBanner } from "../utils/generic";

const { one_signal_key, gtm_id, one_signal_safari_key, env } =
	useRuntimeConfig().public;

const showAds = ref(false);
const $route = useRoute();
const showAdButlerModal = ref(false);
const router = useRoute();
const contentIsReady = ref(false);
const setDesktopCtaText = (newPath) => {
	// let payload;
	// if (newPath === "news" || newPath === "news-slug") {
	// 	payload = {
	// 		title: "Stay Ahead Of The News - Sign Up For My Weekly Newsletter",
	// 		buttonText: "Get My Watchlist Here",
	// 		link:
	// 			"https://timsykeswatchlist.com/organic/?utm_source=blog&utm_medium=ed_mention&utm_campaign=rep0s6"
	// 	};
	// } else {
	const payload = {
		title: "Join Thousands Profiting From Smart Trades!",
		buttonText: "TRADE LIKE TIM",
		link:
			"https://www.timothysykes.com/millionaire-challenge/?utm_source=blog&utm_medium=popup&utm_campaign=Qhdzor"
	};
	// }

	store.setDesktopCtaMessage(payload);
};

const toggleAdButlerModal = async (isScrapped) => {
	/* eslint-disable prefer-const, no-undef */
	let timer;
	let timerCounter = 0;
	let scrollTimeout;

	function detectScrollStop(callback) {
		clearTimeout(scrollTimeout);
		scrollTimeout = setTimeout(callback, 3000);
	}

	if (isScrapped) {
		showAdButlerModal.value = true;
	}

	timer = await setInterval(() => {
		const shouldShowAdButlerModal = localStorage.getItem("showAdButlerModal");

		function scrollHandler() {
			detectScrollStop(function () {
				const shouldShowAdButlerModal = localStorage.getItem("showAdButlerModal");

				const seconds_to_wait = env === "development" ? 3 : 6;

				if (timerCounter >= seconds_to_wait && shouldShowAdButlerModal === "true") {
					localStorage.setItem("showAdButlerModal", "true");
					showAdButlerModal.value = true;

					setTimeout(() => {
						hideModal();
						window.removeEventListener("scroll", scrollHandler);
					}, 25000);

					clearInterval(timer);
				}

				if (shouldShowAdButlerModal === "false" || !shouldShowAdButlerModal) {
					window.removeEventListener("scroll", scrollHandler);
					clearInterval(timer);
				}
			});
		}

		if (
			shouldShowAdButlerModal == null ||
			JSON.parse(shouldShowAdButlerModal) === true
		) {
			localStorage.setItem("showAdButlerModal", "true");
			window.addEventListener("scroll", scrollHandler);
		} else {
			localStorage.setItem("showAdButlerModal", "false");
			clearInterval(timer);
			return;
		}

		timerCounter += 1;
	}, 1000);
};

watch(
	() => $route.name,
	(newPath, oldPath) => {
		if (process.client) {
			if (newPath !== oldPath) {
				setDesktopCtaText(newPath);
			}
		}
		if (newPath !== "blog-slug" && newPath !== "widgetpage") {
			store.toggleContentSidebarVisibility(false);
			store.setContentSidebarId(null);
			store.setContentSidebarData(null);
		}
	}
);
useHead(() => {
	const head_obj = {
		bodyAttrs: {
			class: "full-width-content"
		},
		link: [
			{ rel: "icon", type: "image/x-icon", href: "/favicon.ico" },
			{ rel: "manifest", href: "/site.webmanifest" },
			{
				rel: "icon",
				type: "image/png",
				sizes: "16x16",
				href: "/fav-16x16.png"
			},
			{
				rel: "icon",
				type: "image/png",
				sizes: "32x32",
				href: "/fav-32x32.png"
			},
			{ rel: "mask-icon", color: "#5BBAD5", href: "/safari-pinned-tab.svg" },
			{ rel: "icon", type: "apple-touch-icon", href: "/apple-touch-icon.png" }
		],
		meta: [
			{ name: "msapplication-TileColor", content: "#DA532C" },
			{ name: "theme-color", content: "#FFFFFF" }
		],
		script: [{ src: "/js/prebid.js" }]
	};

	if (env === "production") {
		head_obj.script = [
			{ src: "/js/prebid.js" },
			{
				async: true,
				src: `https://www.googletagmanager.com/gtm.js?id=${gtm_id}`,
				body: true
			},
			{
				async: true,
				src: `https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js`,
				body: true
			}
		];
	}

	return head_obj;
});

const loadAccessibe = () => {
	const s = document.createElement("script");
	const h = document.querySelector("head") || document.body;
	s.src = "https://acsbapp.com/apps/app/dist/js/app.js";
	s.async = true;
	s.onload = function () {
		acsbJS.init({
			statementLink: "",
			footerHtml: "",
			hideMobile: false,
			hideTrigger: false,
			disableBgProcess: false,
			language: "en",
			position: "right",
			leadColor: "#146FF8",
			triggerColor: "#146FF8",
			triggerRadius: "50%",
			triggerPositionX: "right",
			triggerPositionY: "bottom",
			triggerIcon: "people",
			triggerSize: "bottom",
			triggerOffsetX: 20,
			triggerOffsetY: 20,
			mobile: {
				triggerSize: "small",
				triggerPositionX: "right",
				triggerPositionY: "bottom",
				triggerOffsetX: 10,
				triggerOffsetY: 10,
				triggerRadius: "20"
			}
		});
	};
	h.appendChild(s);
};

const hideModal = () => {
	console.log("execute hideModal");
	localStorage.setItem("showAdButlerModal", "false");
	showAdButlerModal.value = false;
};

onMounted(() => {
	if (process.client) {
		const adbutlerModalCookie = localStorage.getItem("showAdButlerModal");

		if (window.adsAreWithUs) {
			showAds.value = true;

			toggleAdButlerModal();
		} else if (
			adbutlerModalCookie === null ||
			JSON.parse(adbutlerModalCookie) === true
		) {
			showAdButlerModal.value = true;
			const isModal = true;
			fetchFallbackAdbutlerBanner(
				"ts-modal",
				"#adbutler-modal-fallback",
				isModal
			).then((value) => {
				if (!value) return;

				const modal = document.querySelector("#adbutler-modal-fallback .modal-ad");

				modal.classList.remove("modal-ad");
				modal.classList.add("ts-modal");
				setTimeout(() => {
					toggleAdButlerModal(true);

					const closeButton = document.querySelector(
						"#adbutler-modal-fallback .adblutler-modal-close"
					);

					if (closeButton) {
						closeButton.addEventListener("click", hideModal);
					}
				}, 2500);
			});
		}
		if (env === "production") {
			const script2 = document.createElement("script");

			script2.innerHTML = `
				window.OneSignalDeferred = window.OneSignalDeferred || [];
				OneSignalDeferred.push(function(OneSignal) {
					OneSignal.init({
					appId: "${one_signal_key}",
					safari_web_id: "${one_signal_safari_key}",
					notifyButton: {
						enable: false, // Disable the default bell notification button
					},
					promptOptions: {
						/* Customize the slide-in prompt here */
						slidedown: {
						enabled: true, // Enable the slide-in prompt
						autoPrompt: true, // Show the prompt automatically
						actionMessage: "Subscribe to receive notifications",
						acceptButtonText: "Allow",
						cancelButtonText: "No, thanks",
						},
					},
					allowLocalhostAsSecureOrigin: true,
					});
				});

				// Apply custom styles
				const style = document.createElement('style');
				style.innerHTML = \`
				@media (max-width: 900px) {
					#onesignal-slidedown-container.slide-up {
						top: 0 !important; /* Move the slider to the top */
						bottom: auto !important; /* Ensure it is not placed at the bottom */
					}
				}
				\`;
				document.head.appendChild(style);
				`;
			document.body.appendChild(script2);
		}

		setDesktopCtaText($route.name);
	}

	loadAccessibe();
});
</script>

<style lang="scss">
@import "../assets/utils.scss";

.swiper-slide p {
	font-size: 15.2px;
}

.swiper-slide-button.prev {
	left: -36px;
	top: -52px;
}

.swiper-slide-button.next {
	right: -36px;
	top: -52px;
}

.testimonial-text .testimonial-author {
	font-size: 20px;
}

.testimonial-text h5 {
	font-size: 10.5px;
}

@media (min-width: 768px) {
	.swiper-slide p {
		font-size: 19px;
	}

	.swiper-slide-button.prev {
		left: 0;
		top: -32px;
	}

	.swiper-slide-button.next {
		right: 0;
		top: -32px;
	}
}

.tablepress {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	border: 1px solid $neutral-200;
	margin-bottom: 32px;
	overflow: auto;
	display: block;
	@media (min-width: 768px) {
		display: table;
	}
	tr:nth-child(2n + 1) {
		background-color: #fff;
	}

	th {
		padding-top: 11px;
		padding-bottom: 11px;
		background-color: $primary-500;
		color: white;
	}

	td,
	th {
		text-align: left;
		padding: 12px 8px;
		white-space: nowrap;
	}
}

@keyframes pulse-red {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px transparent;
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 transparent;
	}
}

.blob.red {
	background: #ff5252;
	box-shadow: 0 0 0 0 #ff5252;
	animation: pulse-red 2s infinite;
}

.blob {
	background: #000;
	border-radius: 50%;
	box-shadow: 0 0 0 0 #000;
	margin: 14px 8px 15px 12px;
	height: 10px;
	width: 10px;
	transform: scale(1);
	animation: pulse-black 2s infinite;
}

.nav-link {
	color: #fff;
	font-family: Fira Sans;
	font-size: 20px;
	font-style: normal;
	height: 38px;
	font-weight: 400;
	line-height: 40px;
	/* 36px */
	letter-spacing: 0.44px;
	position: relative;
	padding: 0 16px;
	visibility: visible;
	display: block;
	&.live {
		border-radius: 8px;
		background: $error-700;
		display: flex;
		padding: 6px 16px;
		align-items: center;
		font-weight: 600;
		gap: 8px;
		line-height: 1;
		font-size: 16px;
		color: white;

		&:hover {
			background: $error-500;
		}

		@include breakpoint(lg) {
			margin-left: 16px;
		}
	}
}

.ad-buttler-ribbon-outer-wrapper {
	background-color: #ffeb3b;
}

.ad-buttler-ribbon-outer-wrapper p {
	color: #222;
}

.author-body {
	a:not(.author-publications) {
		font-size: 18px;
		display: revert-layer;
		align-items: center;
		position: relative;
		color: $primary-500;
		line-height: normal;
		&::after {
			content: "";
			background-image: url("/images/icons/external-link.svg");
			width: 14px;
			height: 14px;
			display: inline-block;
			background-size: contain;
			margin-left: 2px;
		}

		&:hover {
			background-color: #eab308;
			color: #1e3a8a;
			text-decoration: underline;
			&::after {
				background-image: url("/images/icons/external-link-hover.svg");
			}
		}
	}
}

.author-title a {
	color: #93c5fd;
}

.author-side-box {
	grid-column: 3 / span 1;
	grid-row: 1 / span 2;
	width: 100%;
	border-radius: 24px;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	padding: 20px;
	gap: 20px;
	box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.10196078431372549);
	@include breakpoint(md) {
		width: 100%;
		max-width: 380px;
	}

	@include breakpoint(lg) {
		position: sticky;
		top: 92px;
	}
}

.author-watchlist-subscription-ad {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.author-watchlist-image {
	width: 100%;
}

.author-watchlist-image img {
	width: 100%;
	height: auto;
	-o-object-fit: cover;
	object-fit: cover;
	border-radius: 4px;
}

.author-watchlist-information-title {
	padding-top: 24px;
	padding-bottom: 0;
	font-size: 24px;
	font-weight: 700;
	line-height: 29px;
	letter-spacing: -0.5px;
	text-align: left;

	@include breakpoint(lg) {
		font-size: 20px;
	}
	@include breakpoint(xxl) {
		font-size: 26px;
	}
}

.author-watchlist-information-subtitle {
	padding-top: 8px;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0;
	text-align: center;
	color: #1e293b;
	width: 100%;
	display: block;
	margin-bottom: 12px;
}

.author-watchlist-information-email-input::placeholder {
	color: #878788;
	text-align: center;
}

.author-watchlist-information-email-input {
	width: 100%;
	margin-bottom: 8px;
	padding: 10px;
	border: 1px solid #cbd5e1;
	border-radius: 4px;
}

.author-watchlist-information-subscribe-button {
	background-color: #ef6820;
	color: #fff;
	font-weight: 700;
	border: none;
	border-radius: 8px;
	padding: 6px 10px;
	font-size: 16px;
	cursor: pointer;
	position: relative;
	transition: background-color 0.3s ease;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 12px;

	.arrow {
		line-height: 1;
		font-size: 32px;
		height: 32px;
	}
}

.author-watchlist-information-description {
	margin-top: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: Rubik;
	font-size: 12px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0;
	text-align: center;
	color: #1e293b;
}
</style>
