<template>
	<Layout>
		<Row class="bg-white">
			<Container>
				<img
					src="/images/404.jpg"
					class="max-w-[640px] w-full mx-auto mb-[32px]"
				/>
				<div
					class="pl-3 lg:pl-4 border-l-[10px] border-[#27CCFF] max-w-[640px] mx-auto mb-8"
				>
					<p class="avenir-font text-lg mb-[16px] leading-tight">
						Don't worry, all traders lose.
					</p>
					<p class="avenir-font text-lg mb-[16px] leading-tight">
						You might have mistyped the address, or the page might have moved...
					</p>
				</div>

				<h3
					class="avenir-font text-xl font-semibold text-slate-900 text-center mb-[24px]"
				>
					<NuxtLink
						to="/"
						class="underline text-[#27CCFF] hover:text-primary-700"
					>
						Click here for our homepage
					</NuxtLink>
					or search below:
				</h3>
				<div class="flex max-w-[600px] mx-auto mb-[24px] relative flex-col">
					<input
						id="searchInput"
						ref="searchInput"
						type="text"
						name="q"
						placeholder="Search"
						class="search-field bg-slate-100 border-4 border-slate-900 px-[16px] py-[8px] w-full"
						@keydown="searchStuff"
					/>

					<div
						v-if="searchResults && searchResults.length > 0"
						class="top-0 right-0 w-full border border-slate-200 rounded-lg p-4 my-5"
					>
						<NuxtLink
							:key="item.id"
							v-for="item in searchResults"
							:to="item.url.split('.com')[1]"
							class="flex items-start pt-4 hover:text-primary-500"
						>
							<span class="h-9 w-9 flex items-center justify-center mr-3">
								<svg
									class="h-6 w-6"
									width="16"
									height="16"
									viewBox="0 0 16 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M8.14645 3.14645C8.34171 2.95118 8.65829 2.95118 8.85355 3.14645L12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.85355 11.8536C8.65829 12.0488 8.34171 12.0488 8.14645 11.8536C7.95118 11.6583 7.95118 11.3417 8.14645 11.1464L11.2929 8H2.5C2.22386 8 2 7.77614 2 7.5C2 7.22386 2.22386 7 2.5 7H11.2929L8.14645 3.85355C7.95118 3.65829 7.95118 3.34171 8.14645 3.14645Z"
										fill="currentColor"
										fill-rule="evenodd"
										clip-rule="evenodd"
									></path>
								</svg>
							</span>
							<span
								class="pb-4 border-b-[1px] border-neutral-200 font-semibold text-lg w-full"
								v-html="item.title"
							></span>
						</NuxtLink>

						<Button
							label="Clear Search Results"
							class="mx-auto mt-5"
							@click="searchResults = []"
						/>
					</div>
				</div>
			</Container>
		</Row>
		<Row v-if="posts">
			<Container>
				<Heading
					tag="h3"
					class="text-center font-semibold mb-[16px]"
					text="CHECK OUT THE LATEST ARTICLES FROM THE BLOG"
				/>

				<section
					class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-[32px] px-[16px] md:px-[32px] xl:px-0"
				>
					<PostTile
						v-for="post in posts"
						:key="post.id"
						:content="post.content.rendered"
						:source-url="
							post._embedded['wp:featuredmedia'] &&
							post._embedded['wp:featuredmedia'][0]
								? post._embedded['wp:featuredmedia'][0].source_url
								: null
						"
						:title="post.title.rendered"
						:slug="post.slug"
						:category="post.categories[0]"
					/>
				</section>
			</Container>
		</Row>
	</Layout>
	<Head>
		<Title>Page Not Found - Timothy Sykes</Title>
		<Meta
			name="description"
			content="Timothy Sykes is a millionaire penny stock trader and entrepreneur. He is best known for earning $1.65 million by day trading while attending Tulane University."
		/>
	</Head>
</template>

<script setup>
import Layout from "~/layouts/default.vue";
import { Container, Row, PostTile, Heading } from "#components";

async function searchRecords(searchTerm) {
	const response = await fetch(
		`https://content.timothysykes.com/
wp-json/wp/v2/search?per_page=20&page=1&search=${searchTerm}`
	);

	if (!response.ok) {
		return [];
	}

	const data = await response.json();

	// Return the top 6 records
	return data.slice(0, 6);
}

const searchResults = ref([]);
const router = useRoute();
// eslint-disable-next-line
defineProps(["error"]);

const config = useRuntimeConfig();

const API_ENDPOINT = `${config.public.hostname}wp-json/wp/v2/posts/`;

const { data: posts } = await useAsyncData("posts_list", async () => {
	const { data } = await fetchPostsList(API_ENDPOINT);

	return data;
});

const searchTimeout = ref(null);

const searchStuff = (e) => {
	if (searchTimeout.value) {
		clearTimeout(searchTimeout.value);
	}

	searchTimeout.value = setTimeout(async () => {
		const stuff = await searchRecords(e.target.value);
		searchResults.value = stuff;
	}, 500);
};

watch(
	() => router.path,
	(newPath) => {
		if (process.client && newPath !== "") {
			window.location.href = newPath;
		}
	}
);
</script>

<style scoped>
@font-face {
	font-family: "Avenir-Black";
	src: url("../assets/avenir-black.ttf") format("woff2");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

.avenir-font {
	font-family: "Avenir-Black";
}
.site-container {
	background-color: #262930;
	background-size: inherit;
	background-repeat: no-repeat;
	background-position-x: 66%;
	background-position-y: -20%;
	color: #fff;
	width: auto;
}
.nav-404 {
	padding-top: 4rem;
}
.nav-404 span {
	color: #fff;
	font-size: 1.5rem;
	font-weight: 700;
}
.nav-404 span:hover {
	color: #3094fb;
}
.nav-404 a:hover {
	text-decoration: none;
	text-transform: none;
}

.content-404 {
	padding: 65px 0;
	margin-bottom: 32px;
}
.content-404 h1 {
	font-size: 150px;
	font-weight: 400;
}
.content-404 h5 {
	font-size: 20px;
	color: #fff;
	width: 100%;
	font-weight: 400;
}
.content-404 .blue-gradient-big {
	padding: 20px;
	margin-top: 50px;
}
.site-footer {
	padding-top: 25rem;
	border-top: none;
}
@media only screen and (min-width: 768px) {
	.content-404 h1 {
		font-size: 200px;
	}
}
.row {
	padding: 32px 0 !important;
}
@media only screen and (min-width: 1200px) {
	.row {
		padding: 64px 0 !important;
	}
}
</style>
