<template>
	<section
		class="cookie-consent"
		v-if="!hideBanner"
	>
		<Container class="mx-auto cookie-consent-body gap-[16px] relative !px-0">
			<p class="text-sm lg:text-base white-space-nowrap max-lg:text-left">
				We use cookies to provide you with the best online experience. If you
				continue browsing, we consider that you accept our
				<span class="inline-flex">
					<NuxtLink
						to="/cookie-policy"
						class="underline"
						v-html="'Cookie Policy'"
					/>
					,
				</span>
				and also agree to the terms of our

				<span>
					<NuxtLink
						to="/privacy-policy"
						class="underline"
						v-html="'Privacy Policy'"
					/>
					and
					<NuxtLink
						to="/terms-of-service"
						class="underline"
						v-html="'Terms of Use.'"
					/>
				</span>
			</p>
			<button
				class="absolute top-1 lg:top-0 -right-3 lg:right-1 lg:right-0 z-[50]"
				@click="closeCookieConsent"
			>
				<img
					src="/images/close_gray.svg"
					alt="close btn for slide-up"
					width="20"
					height="20"
					class="slide-up-close"
				/>
			</button>
		</Container>
	</section>
</template>

<script setup>
import useBreakpoints from "~/composables/useResolution";

const hideBanner = ref(true);
const consentTimer = ref(null);
const router = useRoute();
const threwError = ref(false);
const { windowWidth, breakpoints } = useBreakpoints();

function updateCookie(name, value, daysToExpire) {
	const expirationDate = new Date();
	expirationDate.setTime(
		expirationDate.getTime() + daysToExpire * 24 * 60 * 60 * 1000
	);
	const expires = `expires=${expirationDate.toUTCString()}`;
	document.cookie = `${name}=${encodeURIComponent(value)}; ${expires}; path=/`;
}

const closeCookieConsent = () => {
	const fab = document.querySelector(".left-fab");
	if (fab && fab.classList.contains("has-cookie-consent")) {
		fab.classList.remove("has-cookie-consent");
	}

	updateCookie(`cookieConsent`, true, 182);
	hideBanner.value = true;
	store.toggleCookieConsentBanner(false);
};

const theSuperMutator = () => {
	const swipe_up_component = document.querySelector("#swipe-up-component");

	// eslint-disable-next-line require-await
	const mutationObserver = new MutationObserver((mutationList, _) => {
		const fab = document.querySelector(".left-fab");
		if (fab) fab.classList.add("has-cookie-consent");
		for (const mutation of mutationList) {
			if (mutation.type === "childList" && mutation.removedNodes.length > 0) {
				const element = mutation.removedNodes[0];
				if (element.className === "cookie-consent") {
					if (fab) {
						fab.classList.remove("has-cookie-consent");
						mutationObserver.disconnect();
					}
				}
			}
		}
	});

	mutationObserver.observe(swipe_up_component, {
		attributes: true,
		childList: true,
		subtree: true
	});
};

const cookieMethod = async () => {
	try {
		const cookieThing = checkCookieConsent();
		if (!cookieThing) {
			hideBanner.value = false;
			store.toggleCookieConsentBanner(true);

			if (windowWidth < breakpoints.lg) {
				theSuperMutator();
			}
			await setTimeout(() => {
				updateCookie("cookieConsent", true, 182);
				hideBanner.value = true;
				store.toggleCookieConsentBanner(false);
			}, 12000);
		}
	} catch (error) {
		threwError.value = true;
		console.error("CookieConsent error: ", error);
	}
};

onMounted(async () => {
	if (process.client) {
		await setTimeout(() => {
			cookieMethod();
		}, 350);
	}
});

watch(
	() => router.name,
	async (newPath) => {
		if (process.client && newPath !== "" && threwError.value) {
			await setTimeout(() => {
				console.log("router change");
				cookieMethod();
			}, 350);
		}
	}
);

watch(
	() => hideBanner.value,
	() => {
		if (hideBanner.value) {
			clearTimeout(consentTimer.value);
		}
	}
);
</script>
<style lang="scss">
@import "~/assets/utils.scss";

.okay-btn {
	border-radius: 8px;
	background: $primary-50;
	border: 3px solid $primary-50;
	color: $primary-900;
	text-align: center;
	font-family: Fira Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 25px; /* 138.889% */
	padding: 2px 12px;

	&.secondary {
		background: $primary-900;
		border: 3px solid $primary-50;
		color: $primary-50;
	}

	@include breakpoint(md) {
		padding: 8px 24px;
		font-size: 18px;
	}
}

.cookie-consent {
	background-color: #1e3a8a;
	color: white;
	padding: 12px 32px 20px 16px;
	border-bottom: 1px solid #3c82f6;
	@include breakpoint(lg) {
		padding: 16px 0;
	}
	height: fit-content;
	width: 100vw;
	margin-top: auto;
	position: relative;
	&.hasSwipe {
		bottom: 90px;
	}
}

.cookie-consent-body {
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	text-align: center;

	@include breakpoint(lg) {
		gap: 16px;
		flex-direction: row;
		text-align: left;
	}
}
</style>
